import axios from 'axios';
import jwt_decode from 'jwt-decode';
import store from '../store';
import { SET_CURRENT_USER } from './types';

const tokenKey = 'token';
const authorizationHeader = 'Authorization';

export const logoutUser = (dispatch) => {
  // Remove token from local storage
  localStorage.removeItem(tokenKey);
  // Remove auth header from requests
  setAuthToken(false);
  // Remove current user
  dispatch(setCurrentUser({}));
}

export const saveAuthToken = (dispatch, token) => {
  // Save token to local storage
  localStorage.setItem(tokenKey, token);
  // Set auth token in header
  setAuthToken(token);
  // Set current user
  const user = jwt_decode(token);
  console.log(JSON.stringify(user));
  // Dispatch sends action to reducer
  dispatch(setCurrentUser(user));
}

export const checkAuthToken = () => {
  // Check if token exists in local storage
  if (localStorage.token) {
    setAuthToken(localStorage.token);
    const user = jwt_decode(localStorage.token);
    store.dispatch(setCurrentUser(user));
    
    // Check if token is expired, if so then log out
    // Get current time in milliseconds
    const currentTime = Date.now() / 1000;
    if (user.exp < currentTime) {
      console.log('Token expired, user being logged out');
      logoutUser(store.dispatch);
      window.location.href = "./login";
    }
  }
}

const setCurrentUser = user => {
  return {
    type: SET_CURRENT_USER,
    payload: user,
    errors: null
  }
}

const setAuthToken = token => {
  if (token) {
    // Apply token to every request if logged in
    axios.defaults.headers.common[authorizationHeader] = token;
  } else {
    // Delete auth header
    delete axios.defaults.headers.common[authorizationHeader];
  }
};