import React from 'react';
import { CircularProgress } from '@material-ui/core';

const PageLoading = props => {
  const loadingStyle = {
    position: 'absolute',
    margin: 0,
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
  };

  return (
    <div style={loadingStyle}>
      {props.show ? <CircularProgress /> : null}
    </div>
  );
};

export default PageLoading;