const initialState = [];

const errorsReducer = (state = initialState, action) => {
  if (action.errors && action.errors.length > 0)
  {
    return action.errors
  }
  return [];
}

export default errorsReducer;