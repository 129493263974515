import React, { useState, useEffect } from 'react';
import { Box, Container, List, ListItem, ListItemText}  from '@material-ui/core';
import { submissionsApi } from '../../api';
import MemberDetailsForm, { useMemberDetailsForm } from './MemberDetailsForm';
import PageLoading from '../layout/PageLoading';

const PastVersionsList = (props) => {
  return (
    <List>
      {props.submissions.map((submission) => (
        <ListItem
          key={`item-${submission.id}`}
          selected={props.selectedSubmission === submission}
          button
          onClick={() => props.setSelectedSubmission(submission)}
        >
          <ListItemText secondary={`Submitted ${new Date(submission.submittedDateUtc).toLocaleDateString()} by ${submission.submittedByUserName}`} />
        </ListItem>
      ))}
    </List>
  );
}

const MemberPastVersions = (props) => {
  const member = props.member;
  const [submissions, setSubmissions] = useState([]);
  const [selectedSubmission, setSelectedSubmission] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [memberDetails, setMemberDetails] = useMemberDetailsForm();

  const convertSubmissionToMemberDetails = (submission) => {
    setSelectedSubmission(submission);
    setMemberDetails(submission ? JSON.parse(submission.data).memberDetails : {});
  };

  useEffect(() => {
    let isMounted = true;
    convertSubmissionToMemberDetails(null);
    const getSubmissions = async () => {
      if (!member?.id) {
        return;
      }
      setIsLoading(true);
      let result = await submissionsApi.list(member.id);
      if (result.success && isMounted) {
        setSubmissions(result.data);
        if (result.data.length > 0) {
          convertSubmissionToMemberDetails(result.data[0]);
        }
      }
      setIsLoading(false);
    };
    getSubmissions();
    // Flag isMounted as false when disposing so that we don't update the submissions on disposal
    return () => {
      isMounted = false
    };
  }, [member]);

  return (
    <>
      <PageLoading show={isLoading} />
      <Box p={2} display="flex" flexDirection="row">
        <PastVersionsList 
          submissions={submissions}
          selectedSubmission={selectedSubmission}
          setSelectedSubmission={convertSubmissionToMemberDetails} />
        <Container fixed>
          {selectedSubmission ? <MemberDetailsForm state={memberDetails} disabled /> : ''}
        </Container>
      </Box>
    </>
  );
};

export default MemberPastVersions;