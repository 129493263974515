import React, { useState, useEffect } from 'react';
import { connect, useSelector, useDispatch } from 'react-redux';
import { Box, Grid, Typography } from '@material-ui/core';
import { Alert } from '@material-ui/lab';
import PageLoading from '../layout/PageLoading';
import LoginForm from './LoginForm';
import { saveAuthToken } from '../../actions/authActions';
import authApi from '../../api/authApi';

const Login = props => {
  const auth = useSelector(state => state.auth);
  const dispatch = useDispatch();
  const [isLoading, setIsLoading] = useState(false);
  const [errors, setErrors] = useState([]);

  useEffect(() => {
    if (auth.isAuthenticated) {
      props.history.push('/');
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [auth]);

  const loginUser = async (username, password) => {
    setIsLoading(true);
    var result = await authApi.loginUser(username, password);
    if (result.success) {
      const { token } = result.data;
      if (token) {
        saveAuthToken(dispatch, token);
      }
    } else {
      setErrors(result.errors);
    }
    setIsLoading(false);
  };

  return (
    <>
      <Grid
        container
        direction="column"
        alignItems="center"
        justify="center"
        style={{ minHeight: '100vh' }}
      >
        <PageLoading show={isLoading} />
        <Grid item xs={3}>
          <Box m={2}>
            <Typography align="center" variant="h5">AWISA Portal Login</Typography>
          </Box>
          <LoginForm onSubmit={loginUser} disabled={isLoading}/>
          <div className="errors" style={{marginTop: 4}}>
            {errors?.map((error => (
              <Alert severity="error" variant="outlined">{error}</Alert>
            )))}
          </div>
        </Grid>
      </Grid>
    </>
  );
}

export default connect()(Login);