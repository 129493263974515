import { SET_CURRENT_USER, USER_LOADING } from '../actions/types';

const isEmpty = require('is-empty');

const initialState = {
  isAuthenticated: false,
  user: {}
};

const authReducer = (state = initialState, action) => {
  switch(action.type) {
    case SET_CURRENT_USER:
      return {
        ...state,
        ...action.payload,
        isAuthenticated: !isEmpty(action.payload),
      };
    case USER_LOADING:
      return {
        ...state,
      };
    default:
      return state;
  }
}

export default authReducer;