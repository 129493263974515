import React, { useEffect, useState }  from 'react';
import { TextField, FormControlLabel, Checkbox } from '@material-ui/core';
import { Autocomplete } from '@material-ui/lab';
import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank';
import CheckBoxIcon from '@material-ui/icons/CheckBox';
import { productsApi }  from '../../api';
import { productsData } from '../../data';

const ProductsField = (props) => {
  const [products, setProducts] = useState([]);
  const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
  const checkedIcon = <CheckBoxIcon fontSize="small" />;

  useEffect(() => {
    let isMounted = true;
    let loadProducts = async () => {
      if (isMounted) {
        var data = await productsData.all();
        setProducts(data);
        if (data.length === 0 && isMounted) { 
          let result = await productsApi.all();
          if (result.success && isMounted) {
            setProducts(result.data);
            productsData.update(result.data);
          }
        }
      }
    };
    loadProducts();
    return () => {
      isMounted = false
    };
  }, []);

  return (
    <Autocomplete
      value={products.filter(p => props.selected.includes(p.value))}
      disabled={props.disabled}
      multiple
      options={
        products.map(product => {
          return {
            firstLetter: product.name.charAt(0),
            ...product
          };
        })
      }
      groupBy={(product) => product.firstLetter}
      onChange={(e, newValue) => {
        e.target.id = props.id;
        e.target.value = newValue.map(p => p.value);
        props.onChange(e);
      }}
      disableCloseOnSelect
      getOptionLabel={product => product?.name ?? ''}
      getOptionSelected={(product, value) => product?.name === value?.name}
      renderOption={(product, state) => (
        <React.Fragment>
          <FormControlLabel
            control={<Checkbox
              icon={icon}
              checkedIcon={checkedIcon}
              style={{ marginRight: 8 }}
              checked={state.selected}
            />}
            label={product.name}
          />
        </React.Fragment>
      )}
      renderInput={(params) => (
        <TextField
          {...params}
          label="Products"
          variant="standard"
          margin="dense"
          placeholder="Type a product name here"
        />
      )}
    />
  );
};

export default ProductsField;