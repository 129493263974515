import db from './';

const productsData = {
  all: () => db.products.toArray(),
  update: async (data) => {
    if (Array.isArray(data)) {
      for(var i = 0; i < data.length; i++) {
        var item = data[i];
        await db.products.put(item);
      }
    } else {
      db.products.put(data)
    }
  },
};

export default productsData;