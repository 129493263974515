import './App.css';
import React from 'react';
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Redirect
} from 'react-router-dom';
import { Provider } from 'react-redux';
import store from './store';
import { checkAuthToken } from './actions/authActions';
import AuthRoute from './components/auth/AuthRoute';
import Login from './components/auth/Login';
import SendResetPasswordRequest from './components/auth/SendResetPasswordRequest';
import ResetPassword from './components/auth/ResetPassword';
import Register from './components/auth/Register';
import Dashboard from "./components/dashboard/Dashboard";
import Admin from './components/admin/Admin';
import Member from './components/member/Member';

// Check auth token state
checkAuthToken();

const App = () =>  {
  return (
    <Provider store={store}>
      <Router>
        <div className="app">
          <header className="app-header">
          </header>
          <Route exact path="/login" component={Login} />
          <Switch>
            <AuthRoute exact path="/" component={Dashboard} />
            <AuthRoute path="/admin" role="admin" component={Admin} />
            <AuthRoute path="/member" component={Member} />
            <Route exact path={"/register/:id"} component={Register} />
            <Route exact path="/resetpassword" component={SendResetPasswordRequest} />
            <Route exact path={"/resetpassword/:id"} component={ResetPassword} />
          </Switch>
        </div>
      </Router>
    </Provider>
  );
}

export default App;
