import axios from 'axios';
import apiResponse from './apiResponse';

const api = {
  get: async (url) => {
    try {
      var result = await axios.get(url, {
        headers: { Authorization: localStorage.getItem('token') },
      });
      return apiResponse.fromResult(result);
    } catch (err) {
      return apiResponse.fromError(err);
    }
  },
  getPaged: async (url) => {
    try {
      var result = await axios.get(url, {
        headers: { Authorization: localStorage.getItem('token') },
      });
      return apiResponse.fromResult(result?.data);
    } catch (err) {
      return apiResponse.fromError(err);
    }
  },
  post: async (url, data) => {
    try {
      var result = await axios.post(url, data, {
        headers: { Authorization: localStorage.getItem('token') },
      });
      return apiResponse.fromResult(result);
    } catch (err) {
      return apiResponse.fromError(err);
    }
  },
  delete: async (url, data) => {
    try {
      var result = await axios.delete(url, {
        headers: { Authorization: localStorage.getItem('token') },
      });
      return apiResponse.fromResult(result);
    } catch (err) {
      return apiResponse.fromError(err);
    }
  },
};

export default api;
