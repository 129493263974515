import React, { useState } from 'react';
import { useLocation, useHistory, useRouteMatch, matchPath } from 'react-router-dom';
import Approvals from './Approvals';
import Submissions from '../submissions/Submissions';

const parentContainer =
{
  display: 'flex',
  flexDirection: 'row'
};

const leftSide =
{
  width: 'auto',
  minWidth: '350px'
};

const rightSide =
{
  width: '100%',
  backgroundColor: "#F2F6FA",
  padding: "15px"
};

const ApprovalsPage = (theme) => {
  const location = useLocation();
  const history = useHistory();
  const { url, path } = useRouteMatch();
  const match = matchPath(location.pathname, { path: `${path}/:submissionId` });
  const [submissionId, setSubmissionId] = useState(match?.params?.submissionId);

  const onSubmissionSelected = (id) => {
    setSubmissionId(id);
    history.push(`${url}${url.endsWith('/') ? id : '/' + id}`);
  };

  const onSubmissionHandled = (id) => {
    // Select first submission
    setSubmissionId(null);
  };

  return (
    <div style={parentContainer}>
      <div style={leftSide}>
        <Submissions submissionId={submissionId} selected={onSubmissionSelected} />
      </div>
      <div style={rightSide}>        
        {
          submissionId && 
          <Approvals submissionId={submissionId} handled={onSubmissionHandled} />
        }
      </div>
    </div>
  );
}

export default ApprovalsPage;