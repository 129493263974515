import React, { useEffect, useState, useCallback } from 'react';
import '../member/MemberAdminDetails.css'
import { connect } from 'react-redux';
import { useHistory, useLocation, Link, Route, HashRouter, withRouter } from 'react-router-dom';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import { Tabs, Tab, Typography } from '@material-ui/core';
import TabPanel from '../layout/TabPanel';
import { membersApi }  from '../../api';
import { membersData } from '../../data';
import MemberEntry from './MemberEntry';
import MemberPastVersions from './MemberPastVersions';
import MemberAccountDetails from '../member/MemberAccountDetails';

const MemberAdminDetails = (props) => {
    const history = useHistory();
    const location = useLocation();
    const [member, setMember] = useState({});

    let currentTab = location.hash ? location.hash : '#entry';

    // Sets the default initial tab
    // This logs a warning but I couldn't find another way of this working
    if (!location.hash) {
      history.replace({ pathname: location.pathname, hash: '#entry' });
    }

    useEffect(() => { 
      let updateMember = async () => {
        var localMember = await membersData.get(props.memberId);
        if (localMember) {
          setMember(localMember);
        }
        var result = await membersApi.get(props.memberId);
        if (result.success) {
          membersData.update(result.data);
          setMember(result.data);
        }
      };
      updateMember();
    }, [props.memberId]);

    const StyledTab = withStyles(theme => ({
      root: {
        color: "#444",
        borderTop: "0px solid #bcbcbc",
        borderRight: "1px solid #bcbcbc",
        borderBottom:"1px solid #bcbcbc",
        fontWeight: theme.typography.fontWeightRegular,
        fontSize: theme.typography.pxToRem(15),
        opacity: "1"
      },
      selected : {
        borderBottom:"1px solid transparent",
        color: "#444",
        backgroundColor: "white"
      }
    }))(props => <Tab component={Link} {...props} />);

    return (
      <div>
          <Typography variant="h6" fontWeight="fontWeightBold">
            {member.name}
          </Typography>

        <div style={{border: "1px solid #bcbcbc", borderRadius: "4px", overflow: "hidden", marginTop: "30px"}}>      
          <HashRouter hashType="noslash" >
            <Tabs
                style={{backgroundColor: "white"}}
                value={currentTab}
                textColor="inherit"
                aria-label="simple tabs"
                TabIndicatorProps={{ style: { display: 'none' } }}>
                <StyledTab label="Entry" value="#entry" to="entry" />
                <StyledTab label="Past Versions" value="#versions" to="versions" />
                <StyledTab label="Account Details" value="#details" to="details" />
                {/* Winston, this is causing errors, not sure why it's needed */}
                {/* <Box flexGrow={1} style={{borderBottom: "1px solid #bcbcbc" }}/> */}
                {/* <div style={{flexGrow:"1", borderBottom: "1px solid #bcbcbc"}}></div> */}
            </Tabs>

            <Route path="/entry">
              <TabPanel value={currentTab} index={0}>
                <MemberEntry member={member} />
              </TabPanel>
            </Route>

            <Route path="/versions">
              <TabPanel value={currentTab} index={1}>
                <MemberPastVersions member={member} />
              </TabPanel>
            </Route>

            <Route path="/details">
              <TabPanel value={currentTab} index={2}>
                <MemberAccountDetails member={member} />
              </TabPanel>
            </Route>
          </HashRouter>
        </div>
      </div>);
};

export default withRouter(MemberAdminDetails);