import React from 'react';
import { TextField } from '@material-ui/core';

const InviteMemberForm = (props) =>  {
  return (
    <>
      <TextField id="memberName"
                  name="memberName"
                  label="Member Name"
                  type="text"
                  margin="dense"
                  variant="outlined"
                  value={props.state.memberName}
                  onChange={props.onChange}
                  disabled={props.disabled}
                  required
                  fullWidth />
      <TextField id="email"
                  name="email"
                  label="Email"
                  type="email"
                  margin="dense"
                  variant="outlined"
                  value={props.state.email}
                  onChange={props.onChange}
                  inputProps={{ maxLength: 100 }}
                  disabled={props.disabled}
                  required
                  fullWidth />
      <TextField id="contactName"
                  name="contactName"
                  label="Contact Name"
                  type="text"
                  margin="dense"
                  variant="outlined"
                  value={props.state.contactName}
                  onChange={props.onChange}
                  disabled={props.disabled}
                  fullWidth />
      <TextField id="contactPhone"
                  name="contactPhone"
                  label="Contact Phone"
                  type="tel"
                  margin="dense"
                  variant="outlined"
                  value={props.state.contactPhone}
                  onChange={props.onChange}
                  inputProps={{ maxLength: 20 }}
                  disabled={props.disabled}
                  fullWidth />
    </>
  );
}

export default InviteMemberForm;