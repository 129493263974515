import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { 
  Box, Container, Link, Typography,
  // Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle 
} 
from '@material-ui/core';
import { DataGrid } from '@material-ui/data-grid';
import { usersApi, invitationsApi } from '../../api';
import PageLoading from '../layout/PageLoading';
import InviteUserDialog from '../inviteUser/InviteUserDialog';

const MemberAccountDetails = (props) => {
  const member = props.member;
  const auth = useSelector(state => state.auth);
  const [mainUser, setMainUser] = useState({});
  const [users, setUsers] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [isInviteOpen, setIsInviteOpen] = useState(false);

  useEffect(() => { 
    if (!member || Object.keys(member).length === 0) {
      // Dont do anything if member hasn't loaded
      return;
    }

    let func = async () => {
      setIsLoading(true);
      var result = await usersApi.list(member.id);
      
      if (result.success) {
        const users = result.data
          .filter(u => u != null)
          .map(u => ({ 
            id: u.id,
            name: u.name,
            email: u.email,
            isMainUser: u.isMainUser,
            isInvitation: u.isInvitation,
          }));
        setUsers(users);
        setMainUser(users.find(u => u.isMainUser) ?? {});
      }
      setIsLoading(false);
    };
    func();
  }, [auth.user, isInviteOpen, member])

  const onCreateInvite = (e) => setIsInviteOpen(true);

  const onResendInvite = async (params) => {
    setIsLoading(true);
    await invitationsApi.resend(params.row.id);
    setIsLoading(false);
  };
  
  const onRemoveUser = async (params) => {
    setIsLoading(true);
    let id = params.row.id;
    var result = await usersApi.delete(id);
    if (result.success) {
      // Fake delete locally to save a call to the server
      setUsers(users.filter(u => u.id !== id));
    }
    setIsLoading(false);
  };

  const onRemoveMember = async (params) => {
    setIsLoading(true);
    // TODO: Show confirmation dialog before deleting
    //await membersApi.delete(params.getValue('id'));
    setIsLoading(false);
  };

  const renderStatus = (params) => {
    let isInvitation = params.row.isInvitation;
    return (
      <>
        {(isInvitation ? 'PENDING' : 'ACTIVE').toUpperCase()}
        {isInvitation 
          ? <Link 
              component="button" 
              variant="body2"
              style={{ marginLeft: 8 }}
              disabled={isLoading} 
              onClick={e => onResendInvite(params)}>
                (resend)
            </Link>
          : null}
      </>
    );
  };

  const renderRemove = (params) => {
    return (
      <>
        {params.row.isMainUser
        ? null
        // Not an admin or main user
        : !['admin', 'main'].includes(auth.role)
          ? null
          : <Link
            component="button" 
            variant="body2"
            disabled={isLoading}
            onClick={e => onRemoveUser(params)}>
              remove
          </Link>
        }
      </>
    );
  }

  const columns = [
    { field: 'name', headerName: 'Name', flex: 0.3 },
    { field: 'email', headerName: 'Email', flex: 0.8, resizable: false },
    { field: 'status', headerName: 'Status', flex: 0.2, resizable: false, renderCell: renderStatus},
    { field: 'remove', headerName: ' ', flex: 0.15, resizable: false, renderCell: renderRemove },
  ];

  return (
    <>
        <PageLoading show={isLoading} />
        <Box p={2} display="flex" flexDirection="row">
          <Box display="flex" flexDirection="column" flexGrow={1}>
            <Typography style={{fontWeight: 600}}>Main Contact</Typography>
            <Typography>{mainUser.name}</Typography>
            <Typography>{mainUser.phone}</Typography>
            <Typography>{mainUser.email}</Typography>
          </Box>
          <Box display="flex" flexDirection="column">
            <Box>
              <Typography>Member since {(member?.createdDateUtc ? new Date(member.createdDateUtc).toLocaleDateString() : '...')}</Typography>
            </Box>
            <Box alignSelf="flex-end">
            {
              auth?.role === 'admin' ? 
              (     
                <Link style={{cursor: 'pointer'}} disabled={isLoading}>
                  Remove this member
                </Link>
              ) : null
            }
            </Box>
          </Box>
        </Box>
        <div style={{ display: 'flex', height: '100%', flexGow: 1, paddingBottom: '16px' }}>
          <DataGrid
            columns={columns} 
            rows={users}
            autoHeight
            disableColumnMenu
            disableColumnReorder
            disableColumnFilter
            disableSelectionOnClick
            hideFooter
          />
        </div>
        {
          !['admin', 'main'].includes(auth.role)
            ? null
            : <Link style={{cursor: 'pointer', paddingLeft: '16px'}} disabled={isLoading} onClick={onCreateInvite}>Create an invite</Link>
        }
        <InviteUserDialog isOpen={isInviteOpen} setIsOpen={setIsInviteOpen} />
    </>
  );
};

export default MemberAccountDetails;