import React, { useState, useEffect } from 'react';
import { Box, Button } from '@material-ui/core';
import PageLoading from '../layout/PageLoading';
import MemberDetailsForm, { useMemberDetailsForm } from './MemberDetailsForm';
import { submissionsApi } from '../../api';

const MemberEntry = (props) => {
  const member = props.member;
  const [isLoading, setIsLoading] = useState(false);
  const [isEditing, setIsEditing] = useState(false);
  const [hasChanges, setHasChanges] = useState(false);
  const [memberDetailsState, setMemberDetails, onMemberDetailsChange] = useMemberDetailsForm();

  const createFakeSubmission = (member) => {
    return {name: member?.name}
  }

  // Populate member details using latest submission
  useEffect(() => {
    setIsEditing(false);
    if (Object.keys(member).length === 0) {
      // Dont do anything if member hasn't loaded
      return;
    }
    let updateForm = async () => {
      setIsLoading(true);

      const submission = await submissionsApi.member(member.id);

      if (submission.success) {
        const memberDetails = submission.data
          // Get the last updated submission
          ? JSON.parse(submission.data.data)?.memberDetails
          // No submission, fake it with the existing members name
          : createFakeSubmission(member);

        if (memberDetails) {
          console.log('Loading member details ' + JSON.stringify(memberDetails));
          setMemberDetails(memberDetails);
        }
      }
      setIsLoading(false);
    };
    updateForm();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [member]);

  const onChange = e => {
    onMemberDetailsChange(e);
    setHasChanges(true);
  };

  const onEdit = e => {
    setIsEditing(true);
  };

  const onSave = e => {
    const save = async () => {
      console.log('Saving member details ' + JSON.stringify(memberDetailsState));
      var result = await submissionsApi.submit(member.id, memberDetailsState);
      if (result.success) {
        await submissionsApi.approve(result.data.submissionId);
      }
      setHasChanges(false);
      setIsLoading(false);
    }
    setIsEditing(false);
    setIsLoading(true);
    save();
  };

  const onViewWebsite = e => {
  };

  return (
    <>
      <PageLoading show={isLoading} />
      <Box p={2} display="flex" flexDirection="column">
        <Box mb={2} display="flex" flexDirection="row">
          <Box flexGrow={1} />
          <Button
            style={{"marginRight": 10}}
            margin="dense"
            variant="outlined"
            disabled={isLoading || (isEditing && !hasChanges)}
            onClick={e => isEditing ? onSave(e) : onEdit(e)}>
              {isEditing ? "SAVE" : "EDIT"}
          </Button>
          <Button
            margin="dense"
            variant="outlined"
            onClick={onViewWebsite}>
              VIEW ON WEBSITE
          </Button>
        </Box>
        <MemberDetailsForm
          state={memberDetailsState}
          disabled={!isEditing} 
          onChange={onChange} />
      </Box>
    </>
  );
};

export default MemberEntry;