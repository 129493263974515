import api from './api';

const apiUrl = '/api/v1/invitations/';

const invitationsApi = {
  newMember: (email, memberName, name, phone) => {
    const data = {
      email: email,
      memberName: memberName,
      name: name,
      phone: phone,
    };
    return api.post(apiUrl + 'newMember', data);
  },
  newUser: (email, name) => {
    const data = {
      email: email,
      name: name,
    };
    return api.post(apiUrl + 'newUser', data);
  },
  resend: (id) => api.post(apiUrl + 'resend/' + id),
  get: (id) => api.get(apiUrl + id)
};

export default invitationsApi;
