import api from './api';
import { membersData } from '../data';

const apiUrl = '/api/v1/members/';

// const withCache = (response) => {
//   response.cache = () => {
//     if (response.data && response.data.id) {
//       membersData.save(response.data.id, response.data);
//     } else if (response.data && Array.isArray(response.data)) {
//       for (var i = 0; i < response.data.length; i++) {
//         var data = response.data[i];
//         membersData.save(data.id, data);
//       }
//     }
//   }
// };

const membersApi = {
  all: (query = '') => api.getPaged(apiUrl + (query ? '?q=' + query : '')),
  get: (id) => api.get(apiUrl + id),
  delete: (id) => api.delete(apiUrl + id),
};

export default membersApi;
