import { MEMBER_LOADED } from '../actions/types';

const initialState = {};

const memberReducer = (state = initialState, action) => {
  switch(action.type) {
    case MEMBER_LOADED:
      return action.payload;
    default:
      return state;
  }
}

export default memberReducer;