import React from 'react';
import { connect, useSelector, useDispatch } from 'react-redux';
import { Button } from '@material-ui/core';
import { logoutUser } from '../../actions/authActions';

const Dashboard = (props) => {
  const auth = useSelector(state => state.auth);
  const dispatch = useDispatch();

  const onLogout = e => {
    e.preventDefault();
    logoutUser(dispatch);
  }

  return (
    <div>
      <h4>Hi {auth.user.name}</h4>
      <p>You are logged in to the AWISA portal</p>
      <Button variant="outlined" onClick={e => props.history.push('/admin/approvals')}>ADMIN</Button>
      <Button variant="outlined" onClick={e => props.history.push('/member')}>MEMBER</Button>
      <Button onClick={onLogout}>SIGN OUT</Button>
    </div>
  );
};

export default connect()(Dashboard);