import React, { useState } from 'react';
import { Button, TextField, Link , Grid } from '@material-ui/core';

const LoginForm = (props) => {
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');

  var onSubmit = e => {
    e.preventDefault();
    props.onSubmit(username, password);
  };

  return (
    <>
      <form onSubmit={onSubmit}>
        <TextField id="username"
                    name="username"
                    label="Email"
                    type="email"
                    margin="dense"
                    variant="outlined"
                    value={username}
                    onChange={e => setUsername(e.target.value)}
                    required
                    fullWidth
                    disabled={props.disabled} />
        <TextField id="password"
                    name="password"
                    label="Password"
                    type="password"
                    margin="dense"
                    variant="outlined"
                    value={password}
                    onChange={e => setPassword(e.target.value)}
                    required
                    fullWidth
                    disabled={props.disabled} />
        <Grid justify="space-between"
              container 
              spacing={24}>
          <Button type="submit"
                  variant="outlined" 
                  color="inherit"
                  style={{marginTop: 4}}
                  disableElevation
                  disabled={props.disabled}>
            SIGN IN
          </Button>
          <Link style={{marginTop: 4, cursor: 'pointer'}}
                href="/resetpassword"
                target="_blank">
            Reset Password
          </Link>
        </Grid>
      </form>
    </>
  );
}

export default LoginForm;