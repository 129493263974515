import React, { useState } from 'react';
import { connect } from 'react-redux';
import { Box, Button, Grid, Typography, TextField } from '@material-ui/core';
import { Alert } from '@material-ui/lab';
import PageLoading from '../layout/PageLoading';
import authApi from '../../api/authApi';

const SendResetPasswordRequest = props => {
  const [username, setUsername] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [message, setMessage] = useState(null);
  const [errors, setErrors] = useState([]);

  const sendResetPasswordRequest = async () => {
    setIsLoading(true);
    let result = await authApi.sendResetPasswordRequest(username);
    setMessage(null);
    setErrors([]);
    console.log(JSON.stringify(result));
    if (result.success) {
      setMessage('Email sent to ' + username);
    } else {
      setErrors(result.errors);
    }
    setIsLoading(false);
  };

  let onSubmit = e => {
    e.preventDefault();
    sendResetPasswordRequest();
  };

  return (
    <>
      <Grid
        container
        direction="column"
        alignItems="center"
        justify="center"
        style={{ minHeight: '100vh' }}
      >
        <PageLoading show={isLoading} />
        <Grid item xs={3}>
          <Box m={2}>
            <Typography align="center" variant="h5">AWISA Portal Reset Password</Typography>
          </Box>
          <form onSubmit={onSubmit}>
            <TextField id="username"
                        name="username"
                        label="Email"
                        type="email"
                        margin="dense"
                        variant="outlined"
                        value={username}
                        onChange={e => setUsername(e.target.value)}
                        required
                        fullWidth
                        disabled={props.disabled} />
              <Button type="submit"
                      variant="outlined" 
                      color="inherit"
                      style={{marginTop: 4}}
                      disableElevation
                      disabled={message != null}>
                SEND PASSWORD RESET EMAIL
              </Button>
          </form>
          {
            message
              ? <div className="message" style={{marginTop: 4}}>
                  <Alert severity="success" variant="outlined">{message}</Alert>
                </div> 
              : null
          }
          <div className="errors" style={{marginTop: 4}}>
            {errors?.map((error => (
              <Alert severity="error" variant="outlined">{error}</Alert>
            )))}
          </div>
        </Grid>
      </Grid>
    </>
  );
}

export default connect()(SendResetPasswordRequest);