import React, { useState, useEffect } from 'react';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import { 
  Grid, Button, TextField, 
  List, ListItem, ListSubheader, ListItemText
} from '@material-ui/core';
import MuiListItem from '@material-ui/core/ListItem';
import { SearchOutlined } from '@material-ui/icons';
import { membersApi } from '../../api';
import { membersData } from '../../data';
import InlineLoading from '../layout/InlineLoading';
import InviteMemberDialog from '../inviteMember/InviteMemberDialog';
import { useRouteMatch, useHistory, useLocation } from 'react-router-dom';

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    maxWidth: 360,
    backgroundColor: 'white',
    position: 'relative',
    overflow: 'auto',
  }
}));

const MembersList = (props) => {
  const classes = useStyles();

  return (
    <List>
      <ListSubheader>MEMBERS
        {/* <Button 
          margin="dense"
          variant="outlined" 
          style={{height: '20px'}}
          >
            Add
        </Button> */}
      </ListSubheader>
      {props.members.map((member) => (
        <ListItem 
          key={`item-${member.id}`}
          button
          selected={props.selectedMember === member}
          onClick={() => props.setSelectedMember(member)}>
          <ListItemText primary={member.name} secondary={member.details.email} />
          
        </ListItem>

      ))}
    </List>
  );
}

const Members = (props) => {
  const [isLoading, setIsLoading] = useState(false);
  const [isAddOpen, setIsAddOpen] = useState(false);
  const [searchTerm, setSearchTerm] = useState('');
  const [members, setMembers] = useState([]);
  const [results, setResults] = useState([]);
  const [selectedMember, setSelectedMember] = useState(null);
  const history = useHistory();
  const location = useLocation();
  const { url } = useRouteMatch();

  const onSearchChange = e => setSearchTerm(e.target.value);
  const onAdd = e => setIsAddOpen(true);

  const onSelectMember = member => {
    setSelectedMember(member);
    let hash = location.hash ?? '#entry';
    let path = (url.endsWith('/') ? member.id : '/' + member.id); 
    history.push(`${url}${path}${hash}`);
  };

  // See: https://stackoverflow.com/questions/53949393/cant-perform-a-react-state-update-on-an-unmounted-component
  // Get members when closing the add member dialog
  useEffect(() => {
    let isMounted = true;
    let func = async () => {
      if (!isAddOpen) {
        setIsLoading(true);
        let localMembers = await membersData.all();
        if (Array.isArray(localMembers) && localMembers.length > 0) {
          setMembers(localMembers);
          setIsLoading(false);
        }
        let result = await membersApi.all();
        if (result.success && isMounted) {
          setMembers(result.data);
          await membersData.update(result.data);
          // Select first member
          if (!selectedMember && result.data && result.data.length > 0) {
            onSelectMember(result.data[0])
          }
        }
      }
      setIsLoading(false);
    };
    func();
    return () => {
      isMounted = false
    };
  }, [isAddOpen]);

  // Filter members
  useEffect(() => {
    const results = members
      .filter(member => member.name.toLowerCase().includes(searchTerm.toLowerCase()));
    setResults(results);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [members, searchTerm]);

  return (
    <div>
          <TextField
            placeholder="Search members"
            margin="dense"
            variant="outlined"
            InputProps={{endAdornment: (<SearchOutlined />)}}
            onChange={onSearchChange}
            disabled={isLoading} style = {{width: "100%", margin: "10px"}}
          />
<div>
<Button 
          margin="dense"
          variant="outlined" 
          onClick={onAdd}
          style={{height: '40px'}}
          disabled={isLoading}>
            ADD
        </Button></div>
      <MembersList
        members={results}
        selectedMember={selectedMember}
        setSelectedMember={onSelectMember} />
      <InlineLoading show={isLoading} />
      <InviteMemberDialog isOpen={isAddOpen} setIsOpen={setIsAddOpen} />
    </div>
  );
};

export default Members;
