export const GET_ERRORS = 'GET_ERRORS';

export const USER_LOADING = 'USER_LOADING';
export const SET_CURRENT_USER = 'SET_CURRENT_USER';

export const MEMBER_LOADED = 'MEMBER_LOADED';

export const SET_CACHE = 'SET_CACHE';
export const REMOVE_CACHE = 'REMOVE_CACHE';

