import { useEffect, useState } from "react";
import { membersApi} from '../../api';
import { membersData } from '../../data';

const MemberName = props => {
  const [member, setMember] = useState(null);

  useEffect(async () => {
    if (!props.id) {
      return;
    }
    let isMounted = true;
    let localMember = await membersData.get(props.id);
    if (localMember && isMounted) {
      setMember(localMember);
    }
    // Load member
    let memberResult = await membersApi.get(props.id);
    if (memberResult.success && isMounted) {
      membersData.update(memberResult.data);
      // Only set member if the id hasn't been changed
      if (props.id === memberResult.id) {
        setMember(memberResult.data);
      }
    }
    return () => {
      isMounted = false
    };
  }, [props.id]);
  return (
    <>{member?.name}</>
  )
};

export default MemberName;