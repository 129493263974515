import React from 'react';
import { CircularProgress } from '@material-ui/core';

const InlineLoading = props => {
  const loadingStyle = { 
    margin: 'auto',
    width: '50%',
    padding: '10px',
    zIndex: '1000',
  };

  return (
    <div style={loadingStyle}>
      {props.show ? <CircularProgress /> : null}
    </div>
  );
};

export default InlineLoading;