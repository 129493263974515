import db from './';

const membersData = {
  all: () => db.submissions.toArray(),
  get: (id) => db.submissions.where('id').equals(id).first(),
  where: (memberId) => db.submissions.where('memberId').equals(memberId).toArray(),
  update: async (data) => {
    if (Array.isArray(data)) {
      for(var i = 0; i < data.length; i++) {
        var item = data[i];
        await db.submissions.put(item);
      }
    } else {
      db.submissions.put(data)
    }
  }
};

export default membersData;