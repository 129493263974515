import api from './api';

const apiUrl = '/api/v1/auth/';

const authApi = {
  loginUser: (username, password) => {
    const userData = {
      username: username,
      password: password
    }
    return api.post(apiUrl + 'login', userData);
  },
  registerUser: (invitationId, name, password) => {
    const userData = {
      invitationId: invitationId,
      name: name,
      password: password,
    }
    return api.post(apiUrl + 'register', userData);
  },
  resetPassword: (userRequestId, password) => {
    const data = {
      userRequestId: userRequestId,
      password: password,
    }
    return api.post(apiUrl + 'resetPassword', data);
  },
  sendResetPasswordRequest: (email) => {
    const data = {
      email: email
    }
    return api.post(apiUrl + 'sendResetPasswordRequest', data);
  }
};

export default authApi;
