import api from './api';
import { http } from '../helpers';

const apiUrl = '/api/v1/users/';

const usersApi = {
  list: (memberId) => api.get(apiUrl + http.query({memberId: memberId}) ),
  delete: (id) => api.delete(apiUrl + id)
};

export default usersApi;
