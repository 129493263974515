import api from './api';

const apiUrl = '/api/v1/brands/';

const brandsApi = {
  all: () => api.get(apiUrl),
  new: (name) => {
    let data = {
      name: name
    };
    return api.post(apiUrl, data);
  },
};

export default brandsApi;
