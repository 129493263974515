import { Alert } from '@material-ui/lab';

const Errors = (props) => {
  return (
    <div className="errors" style={{marginTop: '10px'}}>
      {props.errors?.map((error => (
        <Alert severity="error">{error}</Alert>
      )))}
    </div>
  );
}

export default Errors;