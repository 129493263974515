import api from './api';
import { http } from '../helpers';

const apiUrl = '/api/v1/submissions/';

const submissionsApi = {
  get: (id) => api.get(apiUrl + http.query({id: id})),
  list: (memberId = null) => {
    var query = memberId ? http.query({memberId: memberId}) : '';
    return api.get(apiUrl + 'list' + query); 
  },
  member: (memberId) => api.get(apiUrl + 'member' + http.query({memberId: memberId})),
  save: (memberId, memberDetails) => {
    var data = {
      memberDetails: memberDetails
    };
    return api.post(apiUrl + 'save' + http.query({memberId: memberId}) , data);
  },
  submit: (memberId, memberDetails) => {
    var data = {
      memberDetails: memberDetails
    };
    return api.post(apiUrl + 'submit' + http.query({memberId: memberId}), data);
  },
  apply: (memberId, memberDetails) => {
    var data = {
      memberDetails: memberDetails
    };
    return api.post(apiUrl + 'apply' + http.query({memberId: memberId}), data);
  },
  approve: (submissionId, memberDetails = null) => {
    var data = {
      memberDetails: memberDetails
    };
    return api.post(apiUrl + 'approve/' + submissionId, data);
  },
  reject: (submissionId) => api.post(apiUrl + 'reject/' + submissionId)
};

export default submissionsApi;
