import React from 'react';
import { Route, Redirect } from 'react-router-dom';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

const AuthRoute = ({ component: Component, auth, role, ...rest }) => (
  <Route
    {...rest}
    render={
      props =>
        // Check if authenticated 
        auth && auth.isAuthenticated === true && 
        // Check if authorized
        (!role || (auth.user && auth.role === role))
          ? (<Component {...props} />)
          : (<Redirect to="/login" />)
    }
  />
);

AuthRoute.propTypes = {
  auth: PropTypes.object.isRequired
};

const mapStateToProps = state => ({
  auth: state.auth
});

export default connect(mapStateToProps)(AuthRoute);
