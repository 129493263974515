import { useReducer } from 'react';

const useForm = (initialValues) => {
  const [state, dispatch] = useReducer(formReducer, initialValues);

  const setForm = updatedState => {
    Object.keys(state).forEach(id => {
      let value = updatedState[id] ?? ''; 
      if (value || value === '') {
        dispatch({id, value});
      }
    }, {});
  };

  const changeHandler = ({target: {value, id}}) => {
    // Items implementation, one value at a time (e.g. checkbox control)
    if (id.includes('_checkbox') && !Array.isArray(value)) {
      let currentItems = state[id] = state[id] ? state[id] : [];
      let index = currentItems.indexOf(value);
      // Item contained in array
      if (index > -1) {
        currentItems.splice(index, 1);
      // Item is not contained in array
      } else {
        currentItems.push(value);
      }
      value = currentItems;
    }
    // Items implementation, multiple values at a time (e.g. autocomplete control)
    else if (id.includes('_items') && !Array.isArray(value)) {
      let currentItems = state[id] = state[id] ? state[id] : [];
      value = value ? value.split(',') : currentItems;
    // Standard implementation
    } else {
      state[id] = value;
    }
    dispatch({id, value});
  };
  
  return [state, setForm, changeHandler];
}

const formReducer = (prevState, {id, value}) => {
  return {...prevState, [id]: value};
};

export default useForm;