import React from 'react';
import { useDispatch } from 'react-redux';
import { logoutUser } from '../../actions/authActions';
import { useLocation, useRouteMatch, matchPath, Link, Redirect, Route, HashRouter } from 'react-router-dom';
import { AppBar, Tabs, Tab, Button, Toolbar, Typography, Box, Divider } from '@material-ui/core';
import { makeStyles, withStyles, createTheme } from '@material-ui/core/styles';
import ApprovalsPage from '../approvals/ApprovalsPage';
import MembersPage from '../members/MembersPage';
import TabPanel from '../layout/TabPanel';

const Admin = () => {
  const dispatch = useDispatch();

  const onLogout = e => {
    // Prevent browser reload/refresh
    e.preventDefault();
    logoutUser(dispatch);
  }

  const useStyles = makeStyles((theme) => (
    {
      root: {
        backgroundColor: theme.palette.background.paper
      }
    }));

  const StyledTab = withStyles(
    {
      root: {
        textTransform: 'none',
        marginRight: '25px',
        padding: '0px',
        minWidth: '0px',
        letterSpacing: '0',
        '@media (min-width: 0px)': {
          minWidth: 0
        },
        "&.Mui-selected": {
          color: '#678af3',
          fontWeight: 'bold'
        }
      }
    })(Tab);

  const location = useLocation();
  const { path } = useRouteMatch();

  let currentTab = '/' + location.pathname.split('/').filter(s => s.length > 0).slice(0,2).join('/');

  return (
    <div className={useStyles.root}>
      {/* <Typography variant='h6' style={{ margin: '25px 12px 3px 25px', fontWeight: 'bolder', color: '#555' }}>AWISA Data Collector 2021</Typography> */}
      <AppBar color={"inherit"} position="relative" elevation={4}>
        <Toolbar variant="dense">
          <Tabs
            value={currentTab}   
            aria-label="simple tabs example"
            style={{bottom:"0px", position:"absolute"}}
            TabIndicatorProps={{ style: { backgroundColor: "#678af3", height: 4 } }}>
            <StyledTab disableRipple label="Approvals" 
              value={`${path}/approvals`}
              to={`${path}/approvals`}
              component={Link} />

            <StyledTab disableRipple label="All Members"
              value={`${path}/members`}
              to={`${path}/members`}
              component={Link} />
          </Tabs>

          <img style={{marginTop:"5px", marginBottom:"5px", marginLeft:"auto", marginRight:"auto"}} src={require('../../awisa_admin_logo.png').default} />

          <Button variant="contained" color="secondary" style={{ backgroundColor: "#678af3", fontSize: "10px", position: "absolute", right:"0", marginRight:"10px" }} onClick={onLogout}>Sign Out</Button>
        </Toolbar>
      </AppBar>

      <div>
        <Route path={`${path}/approvals`}>
          <TabPanel value={currentTab} active={`${path}/approvals`} index={0}>
            <ApprovalsPage />
          </TabPanel>
        </Route>

        <Route path={`${path}/members`}>
          <TabPanel value={currentTab} active={`${path}/members`} index={1}>
            <MembersPage />
          </TabPanel>
        </Route>
      </div>
    </div>
  );
}

export default Admin;