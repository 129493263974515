import React from 'react';
import { useLocation, useRouteMatch, matchPath } from 'react-router-dom';
import Members from './Members'
import MemberAdminDetails from '../member/MemberAdminDetails';

const parentContainer =
{
  display: 'flex',
  flexDirection: 'row'
};

const leftSide =
{
  width: 'auto',
  minWidth: '350px'
};

const rightSide =
{
  width: '100%',
  backgroundColor: "#f4f4f4",
  padding: "15px"
};

const MembersPage = (theme) => {
  const location = useLocation();
  const { path } = useRouteMatch();
  const match = matchPath(location.pathname, { path: `${path}/:memberId` });

  return (
    <div style={parentContainer}>
      <div style={leftSide}>
        <Members />
      </div>
      <div style={rightSide}>        
        {
          match?.params?.memberId && 
          <MemberAdminDetails memberId={match?.params?.memberId} />
        }
      </div>
    </div>
  );
}

export default MembersPage;