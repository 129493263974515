import React, { useEffect, useState }  from 'react';
import Autocomplete, { createFilterOptions } from '@material-ui/lab/Autocomplete';
import { TextField, FormControlLabel, Checkbox } from '@material-ui/core';
import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank';
import CheckBoxIcon from '@material-ui/icons/CheckBox';
import { brandsApi }  from '../../api';
import { brandsData } from '../../data';

const filter = createFilterOptions();

const BrandsField = (props) => {
  const [brands, setBrands] = useState([]);
  const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
  const checkedIcon = <CheckBoxIcon fontSize="small" />;

  useEffect(() => {
    let isMounted = true;
    let loadBrands = async () => {
      if (isMounted) {
        let result = await brandsApi.all();
        if (result.success && isMounted) {
          setBrands(result.data);
          brandsData.update(result.data);
        }
      }
    };
    loadBrands();
    return () => {
      isMounted = false
    }
  }, []);

  return (
    <Autocomplete
      multiple
      freeSolo
      clearOnBlur
      disabled={props.disabled}
      disableCloseOnSelect
      options={
        brands.map(brand => {
          return {
            firstLetter: brand.name.charAt(0),
            ...brand
          };
        })
        .sort((a, b) => -b.firstLetter.localeCompare(a.firstLetter))
      }
      groupBy={(option) => option.firstLetter}
      value={brands.filter(p => props.selected.includes(p.id))}
      filterOptions={(options, params) => {
        const filtered = filter(options, params);
        // Suggest the creation of a new value
        if (filtered.length === 0 && params.inputValue !== '') {
          filtered.push({
            name: params.inputValue,
          });
        }
        return filtered;
      }}
      getOptionLabel={(option) => option.name}
      onChange={async (e, newValue) => {
        let newOptions = newValue.filter(v => !v.id);
        if (newOptions.length > 0) {
          let newOption = newOptions[0];
          if (!newOption) {
            return;
          }
          newOption.name = newOption.name.toUpperCase();
          let result = await brandsApi.new(newOption.name);
          if (result.success) {
            newOption.id = result.data;
            brands.push(newOption);
            setBrands(brands);
          }
        }
        e.target.id = props.id;
        e.target.value = newValue.map(p => p.id);
        props.onChange(e);
      }}
      getOptionLabel={option => option?.name ?? ''}
      getOptionSelected={(option, value) => option?.id === value?.id}
      renderOption={(option, state) => {
        let name = option.name;
        let control = (<Checkbox
          icon={icon}
          checkedIcon={checkedIcon}
          style={{marginRight: 8}}
          checked={state.selected}
        />);
        if (!option.id) {
          name = `Add "${option.name}"`;
          control = <div/>;
        }
        return (
          <React.Fragment>
            <FormControlLabel
              control={control}
              label={name}
            />
          </React.Fragment>
        );
      }}
      renderInput={(params) => (
        <TextField
          {...params}
          label="Brands"
          variant="standard"
          margin="dense"
          placeholder="Type a brand name here"
        />
      )}
    />
  );
};

export default BrandsField;