import { SET_CACHE, REMOVE_CACHE } from '../actions/types';

const initialState = {
};

const cacheReducer = (state = initialState, action) => {
  switch(action.type) {
    case SET_CACHE:
      return {
        ...state,
        ...action.payload,
      };
    case REMOVE_CACHE:
      delete state[action.payload];
      return state;
    default:
      return state;
  }
}

export default cacheReducer;