import React, { useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Box, Typography, List, ListSubheader, ListItem, ListItemText } from '@material-ui/core';
import { submissionsApi } from '../../api';
import InlineLoading from '../layout/InlineLoading';

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    maxWidth: 360,
    backgroundColor: 'white',
    position: 'relative',
    overflow: 'auto',
    // maxHeight: 300,
  },
  listSection: {
    backgroundColor: 'inherit',
  },
  ul: {
    backgroundColor: 'inherit',
    padding: 0,
  },
}));

const Submissions = (props) => {
  const [isLoading, setIsLoading] = useState(false);
  const [recent, setRecent] = useState([]);
  const [approved, setApproved] = useState([]);
  const [submissions, setSubmissions] = useState([]);
  const classes = useStyles();

  let loadSubmissions = async () => {
    setIsLoading(true);
    let result = await submissionsApi.list();
    if (result.success) {
      setRecent(result.data.recent);
      setApproved(result.data.approved);
      setSubmissions(result.data.recent.concat(result.data.approved));
    }
    setIsLoading(false);
  };

  // Refresh submissions if nothing is loaded or the submission selected is reset (null)
  useEffect(() => {
    let refreshSubmissions = async () => {
      let haveSubmissionsLoaded = (approved.length > 0 || recent.length > 0)
      if (!props.submissionId || !haveSubmissionsLoaded) {
        // Reload submissions
        await loadSubmissions();
      }
    };
    refreshSubmissions();
  }, [props.submissionId]);

  // Set first submission if nothing selected
  useEffect(() => {
    if (!props.submissionId) {
      let submission = recent.find(_ => true) ?? approved.find(_ => true);
      if (submission) {
        props.selected(submission?.id);
      }
    }
  }, [submissions])

  const onSetSelectedSubmission = (submission) => {
    if (props.selected) {
      props.selected(submission?.id);
    }
  };

  return (
    <Box style={{ margin: '0px' }}>
      <List className={classes.root}>
        <li key='section-recent' className={classes.listSection}>
          <ul className={classes.ul}>
            <ListSubheader>RECENT SUBMISSIONS</ListSubheader>
            {recent.length > 0 ? recent.map((submission, i) => (
              <ListItem 
                key={`item-${submission.id}`}
                selected={props.submissionId === submission.id}
                button
                onClick={e => onSetSelectedSubmission(submission)}>
                <ListItemText
                   disableTypography
                   primary={`Member ${submission.memberName}`}
                   secondary={`Submitted ${(new Date(submission.submittedDateUtc)).toLocaleDateString()} by ${submission.submittedByUserName}`} />
              </ListItem>
            )) : <span style={{marginLeft: "15px", marginTop:"10px", marginBottom: "10px", display: "inline-block", fontSize: "13px", color: "#3C3C3C"}}>No recent submissions</span>}
          </ul>
        </li>

        <ListSubheader>LAST APPROVED SUBMISSIONS</ListSubheader>
        {approved.map((submission, i) => (
          <ListItem
            key={`item-${submission.id}`}
            selected={props.submissionId === submission.id}
            button
            onClick={e => onSetSelectedSubmission(submission)}>
            <ListItemText        
              primary={`Member ${submission.memberName}`}
              secondary={`Approved ${(new Date(submission.approvedDateUtc)).toLocaleDateString()}`} />
          </ListItem>
        ))}
        <InlineLoading show={isLoading} />
      </List>
    </Box>
  );
};

export default Submissions;