const apiResponse = {
  fromResult: (result) => {
    let warnings = result.data?.warnings;

    if (warnings) {
      console.log(JSON.stringify(warnings));
    }

    return {
      data: result.data,
      warnings: warnings,
      success: true
    };
  },
  fromError: (err) => {
    let errors = [err];

    if (err.response) {
      errors = err.response.data.errors;
    }

    console.log(JSON.stringify(errors));
    
    return {
      errors: errors,
      success: false
    }
  }
}

export default apiResponse;

