import db from './';

const membersData = {
  all: () => db.members.toArray(),
  get: (id) => db.members.where('id').equals(id).first(),
  update: async (data) => {
    if (Array.isArray(data)) {
      for(var i = 0; i < data.length; i++) {
        var item = data[i];
        await db.members.put(item);
      }
    } else {
      db.members.put(data)
    }
  },
};

export default membersData;