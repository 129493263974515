import React, { useEffect, useState } from 'react';
import { connect, useDispatch, useSelector } from 'react-redux';
import { Route, useRouteMatch, useHistory, Link, useLocation } from 'react-router-dom';
import { Tabs, Tab, Box, Typography, Button } from '@material-ui/core';
import { MEMBER_LOADED } from '../../actions/types';
import { logoutUser } from '../../actions/authActions';
import { membersApi }  from '../../api';
import MemberDetails from './MemberDetails';
import MemberAccountDetails from './MemberAccountDetails';

const Member = (props) => {
  const dispatch = useDispatch();
  const auth = useSelector(state => state.auth);
  const member = useSelector(state => state.member);
  const memberId = auth.user.memberId;

  // Get member
  useEffect(() => { 
    let updateMember = async () => {
      var result = await membersApi.get(memberId);

      if (result.success) {
        dispatch({
          type: MEMBER_LOADED,
          payload: result.data
        })
      }
    };
    updateMember();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onLogout = e => {
    e.preventDefault();
    logoutUser(dispatch);
  };

  let location = useLocation();
  let { path, url } = useRouteMatch();
  let history = useHistory();

  let currentTab = "/" + location.pathname.split("/").filter(s => s.length > 0).slice(0,2).join("/");

  const rootStyle = { 
    display: "grid", 
    gridTemplateColumns:"150px 1fr", 
    gridTemplateAreas: `"header header"
                        "nav main"`,
    gridTemplateRows: "auto 1fr",
    height: "100%"
  };

  return (
    <div style={rootStyle}>
      <header style={{display:"flex", gridArea:"header", padding: "20px", borderBottom: "1px solid #CCC"}}>
        <Typography variant="h6" style={{fontWeight: 550}}>{member?.name}</Typography>
        <Box flexGrow={1} />
        <Button onClick={onLogout}>Sign Out</Button>
      </header>

      <nav style={{gridArea:"nav", borderRight: "1px solid #CCC"}}>
          <Tabs
            value={currentTab}       
            textColor="inherit"
            aria-label="Vertical tabs example"
            orientation="vertical"
            TabIndicatorProps={{ style: { backgroundColor: 'black', width:"3px" } }}>
            <Tab label="Details" value={`${url}/details`}
              to={`${url}/details`}
              component={Link} />
            <Tab label="Account" value={`${url}/account`}
              to={`${url}/account`}
              component={Link} />
          </Tabs>
      </nav>

      <main style={{padding: "25px", gridArea:"main"}}>
        <Route path={`${url}/details`} component={() => <MemberDetails member={member} />} />
        <Route path={`${url}/account`} component={() => <MemberAccountDetails member={member} />} />
      </main>
    </div>
  );
}

export default connect()(Member);