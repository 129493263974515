import React from 'react';
import { TextField } from '@material-ui/core';

const InviteMemberForm = (props) =>  {
  return (
    <>
      <TextField 
        id="email"
        name="email"
        label="Email"
        type="email"
        margin="dense"
        variant="outlined"
        value={props.state.email}
        onChange={props.onChange}
        inputProps={{ maxLength: 100 }}
        disabled={props.disabled}
        required
        fullWidth />
      <TextField 
        id="name"
        name="name"
        label="Name"
        type="text"
        margin="dense"
        variant="outlined"
        value={props.state.contactName}
        onChange={props.onChange}
        disabled={props.disabled}
        required
        fullWidth />
    </>
  );
}

export default InviteMemberForm;