import { combineReducers } from 'redux';
import errorsReducer from './errorsReducer';
import authReducer from './authReducer';
import memberReducer from './memberReducer';
import cacheReducer from './cacheReducer';

export default combineReducers({
  errors: errorsReducer,
  auth: authReducer,
  member: memberReducer,
  cache: cacheReducer,
});
