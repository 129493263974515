import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { TextField } from '@material-ui/core';
import ProductsField from '../products/ProductsField';
import BrandsField from '../brands/BrandsField';
import { useForm } from '../../hooks';
import { Filter } from '@material-ui/icons';

export function useMemberDetailsForm(initialValues = null) {
  const memberDetails = {
    name: initialValues?.name || '',
    description: initialValues?.description || '',
    stand: initialValues?.stand || '',
    address: initialValues?.address || '',
    email: initialValues?.email || '',
    phone: initialValues?.phone || '',
    website: initialValues?.website || '',
    products_items: initialValues?.products_items || [],
    brands_items: initialValues?.brands_items || [],
  };

  return useForm(memberDetails);
}

const MemberDetailsForm = (props) => {
  return (
    <>
      <TextField
        id='name'
        name='name'
        label='Name'
        type='text'
        margin='dense'
        variant='standard'
        value={props.state.name ?? ''}
        onChange={props.onChange}
        disabled={props.disabled}
        fullWidth
      />
      <TextField
        id='description'
        name='description'
        label='Description'
        multiline
        type='text'
        margin='dense'
        variant='standard'
        rows={4}
        maxRows={4}
        value={props.state.description ?? ''}
        onChange={props.onChange}
        disabled={props.disabled}
        fullWidth
      />
      <TextField
        id='stand'
        name='stand'
        label='Stand'
        type='text'
        margin='dense'
        variant='standard'
        value={props.state.stand ?? ''}
        onChange={props.onChange}
        disabled
        fullWidth
      />
      <TextField
        id='address'
        name='address'
        label='Address'
        type='text'
        margin='dense'
        variant='standard'
        value={props.state.address ?? ''}
        onChange={props.onChange}
        inputProps={{ maxLength: 500 }}
        disabled={props.disabled}
        fullWidth
        multiline
      />
      <TextField
        id='email'
        name='email'
        label='Email'
        type='email'
        margin='dense'
        variant='standard'
        value={props.state.email ?? ''}
        onChange={props.onChange}
        inputProps={{ maxLength: 100 }}
        disabled={props.disabled}
        fullWidth
      />
      <TextField
        id='phone'
        name='phone'
        label='Phone'
        type='tel'
        margin='dense'
        variant='standard'
        value={props.state.phone ?? ''}
        onChange={props.onChange}
        inputProps={{ maxLength: 20 }}
        disabled={props.disabled}
        fullWidth
      />
      <TextField
        id='website'
        name='website'
        label='Website'
        type='text'
        margin='dense'
        variant='standard'
        value={props.state.website ?? ''}
        onChange={props.onChange}
        inputProps={{ maxLength: 300 }}
        disabled={props.disabled}
        fullWidth
      />

      <ProductsField
        id='products_items'
        selected={props.state.products_items}
        disabled={props.disabled}
        onChange={props.onChange}
      />

      <BrandsField
        id='brands_items'
        selected={props.state.brands_items}
        disabled={props.disabled}
        onChange={props.onChange}
      />
    </>
  );
};

export default connect()(MemberDetailsForm);
