import React, { useEffect, useState } from 'react';
import { Button, Dialog, DialogActions, DialogContent } from '@material-ui/core';
import { invitationsApi } from '../../api';
import { useForm } from '../../hooks';
import PageLoading from '../layout/PageLoading';
import Errors from '../errors/Errors';
import InviteUserForm from './InviteUserForm';
import CustomDialogTitle from '../layout/CustomDialogTitle';

const InviteUserDialog = (props) => {
  const isOpen = props.isOpen;
  const setIsOpen = props.setIsOpen;
  const [isLoading, setIsLoading] = useState(false);
  const [errors, setErrors] = useState([]);

  // Clear errors when opening dialog
  useEffect(() => setErrors([]), [isOpen]);

  const initialForm = {
    email: '',
    name: '',
  };

  const [state, setForm, changeHandler] = useForm(initialForm);

  const onSendInvite = async (e) => {
    e.preventDefault();
    // Clear errors
    setErrors([]);
    setIsLoading(true);
    const result = await invitationsApi.newUser(state.email, state.name);
    if (result.success) {
      setIsOpen(false);
    } else {
      setErrors(result.errors);
    }
    setIsLoading(false);
  };
  const onCancel = e => setIsOpen(false);

  return(
    <>
      <Dialog open={isOpen} onClose={onCancel} aria-labelledby="form-dialog-title">
        <form onSubmit={onSendInvite}>
          <CustomDialogTitle id="form-dialog-title" onClose={onCancel}>
            Invite a new user
          </CustomDialogTitle>
          <DialogContent>
            <PageLoading show={isLoading} />
            <InviteUserForm 
              state={state}
              onChange={changeHandler}
              disabled={isLoading} />
            <Errors errors={errors} />
          </DialogContent>
          <DialogActions>
            <Button onClick={onCancel} disabled={isLoading}>
              Cancel
            </Button>
            <Button type="submit" color="primary" disabled={isLoading}>
              Send
            </Button>
          </DialogActions>
        </form>
      </Dialog>
    </>
  );
};

export default InviteUserDialog;