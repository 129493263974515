import React from 'react';

const TabPanel = (props) => {
    const { children, value, active, index, ...other } = props;
  
    return (
      <div
        role="tabpanel"
        style={{backgroundColor: "white"}}
        id={`simple-tabpanel-${index}`}
        aria-labelledby={`simple-tab-${index}`}
        {...other}>
            {children}
      </div>
    );
  }

  export default TabPanel;