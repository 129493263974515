import { FirstPage } from '@material-ui/icons';
import Dexie from 'dexie';

var currentVersion = 10;

// Databases
var db = new Dexie('awisa');
db.version(currentVersion).stores({
  versions: '&id,data',
  members: '++id,data',
  submissions: '++id,memberId,data',
  products: '++name,data',
  brands: '++id, data',
});

// Scripts to perform local db updates (e.g. clearing old data)
db.versions.count().then(async (count) => {
  if (count === 0) {
    // Update version
    await db.versions.put({id: 'version', version: currentVersion});
  }
  var result = await db.versions.get('version');
  console.log('AWISA local db version ' + currentVersion);
  // Version has changed - run scripts here
  if (!result || result.version != currentVersion) {
    console.log('Clearing product data...');
    await db.products.clear();
  }
  // Update version
  await db.versions.put({id: 'version', version: currentVersion});
});

export {default as membersData} from './membersData';
export {default as submissionsData} from './submissionsData';
export {default as productsData} from './productsData';
export {default as brandsData} from './brandsData';
export default db;