import React, { useEffect, useState } from 'react';
import { connect, useSelector, useDispatch } from 'react-redux';
import { Box, Grid, Typography, Button, TextField, CircularProgress } from '@material-ui/core';
import { Alert } from '@material-ui/lab';
import { saveAuthToken } from '../../actions/authActions';
import { authApi, userRequestsApi } from '../../api';

const useUserRequest = (userRequestId, setIsLoading, setErrors) => {
  const [userRequest, setUserRequest] = useState({});
  const [isExpired, setIsExpired] = useState(false);

  // [] as parameter - Run on initial render only, not further state changes
  // No parameter - Will run on initial render AND further state changes
  useEffect(() => {
    setIsLoading(true);
    let updateUserRequest = async () => {
      const result = await userRequestsApi.get(userRequestId);
      console.log('result.success ' + result.success);
      setIsExpired(!result.success);
      if (result.success) {
        setUserRequest(result.data);
      } else {
        setErrors(result.errors);
      }
      setIsLoading(false);
    };
    updateUserRequest();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return [userRequest, isExpired];
};

const ResetPassword = props => {
  const auth = useSelector(state => state.auth);
  const dispatch = useDispatch();
  const [isLoading, setIsLoading] = useState(true);
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [errors, setErrors] = useState([]);

  const userRequestId = props.match.params.id;
  const [userRequest, isExpired] = useUserRequest(userRequestId, setIsLoading, setErrors);

  useEffect(() => {
    if (auth.isAuthenticated) {
      props.history.push('/member/details');
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [auth]);

  const resetPassword = async (userRequestId, password) => {
    if (password != confirmPassword) {
      setErrors(['Passwords do not match']);
      return;
    }

    setIsLoading(true);
    let result = await authApi.resetPassword(userRequestId, password, confirmPassword);
    if (result.success) {
      const { token } = result.data;
      if (token) {
        saveAuthToken(dispatch, token);
      }
    } else {
      setErrors(result.errors);
    }
    setIsLoading(false);
  };

  const onSubmit = e => {
    e.preventDefault();
    resetPassword(userRequestId, password);
  };

  const loadingStyle = { 
    position: 'absolute',
    margin: 0,
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
  };

  if (isLoading) {
    return (
      <div style={loadingStyle}>
        <CircularProgress />
      </div>
    );
  }

  if (isExpired) {
    return (<Typography>{errors}</Typography>);
  }

  return (
    <>
      <Grid
        container
        direction="column"
        alignItems="center"
        justify="center"
        style={{ minHeight: '100vh' }}
      >
        <Grid item xs={3}>
          <Box m={2}>
            <Typography align="center" variant="h5">AWISA Portal Reset Password</Typography>
          </Box>
          <form onSubmit={onSubmit}>
            <TextField id="email"
                      value={userRequest.email}
                      label="Email"
                      type="email"
                      name="email"
                      margin="dense"
                      variant="outlined"
                      required
                      disabled
                      fullWidth />
            <TextField id="password"
                      value={password}
                      onChange={e => setPassword(e.target.value)}
                      label="Password"
                      type="password"
                      margin="dense"
                      variant="outlined"
                      required
                      fullWidth />
            <TextField id="confirmpassword"
                      value={confirmPassword}
                      onChange={e => setConfirmPassword(e.target.value)}
                      label="Confirm Password"
                      type="password"
                      margin="dense"
                      variant="outlined"
                      required
                      fullWidth />
            <Button type="submit"
                    variant="outlined" 
                    color="inherit"
                    style={{marginTop: 4}}
                    disableElevation>
                RESET PASSWORD
            </Button>
          </form>
          <div className="errors"
               style={{marginTop: 4}}>
            {errors?.map((error => (
              <Alert severity="error" variant="outlined">{error}</Alert>
            )))}
          </div>
        </Grid>   
      </Grid> 
    </>
  );
};

export default connect()(ResetPassword);