import React, { useState, useEffect } from 'react';
import { Box, Button, Typography } from '@material-ui/core';
import { submissionsApi } from '../../api';
import { submissionsData } from '../../data';
import PageLoading from '../layout/PageLoading';
import MemberDetailsForm, { useMemberDetailsForm } from '../member/MemberDetailsForm';
import MemberName from '../member/MemberName';

const Approvals = (props) => {
  const [isLoading, setIsLoading] = useState(false);
  const [isDisabled, setIsDisabled] = useState(false);
  const [hasChanges, setHasChanges] = useState(false);
  const [submission, setSubmission] = useState({});
  const [cachedMemberDetails, setCachedMemberDetails] = useState(null);
  const [memberDetails, setMemberDetails, onMemberDetailsChange] = useMemberDetailsForm();

  let submissionId = props.submissionId;

  useEffect(() => {
    let isMounted = true;
    let loadMemberDetailsForSubmission = (data) => {
      setSubmission(data);
      // Load member details
      if (data) {
        const memberDetails = JSON.parse(data.data)?.memberDetails;
        if (memberDetails && isMounted) {
          setMemberDetails(memberDetails);
          // Used for reverting
          setCachedMemberDetails(memberDetails);
        }
      }
      setIsDisabled((data.approvedDateUtc || data.unapprovedDateUtc) ? true : false);
      setIsLoading(false);
    };

    let loadSubmission = async () => {
      setIsLoading(true);
      setHasChanges(false);
      setCachedMemberDetails(null);

      // Load submission
      // Try local submission first
      let result = await submissionsData.get(submissionId);
      if (result) {
        loadMemberDetailsForSubmission(result);
      }
      // Get submission from the server
      result = await submissionsApi.get(submissionId);
      if (result.success) {
        // Cache submission for later
        await submissionsData.update(result.data);
        result = result.data;
        loadMemberDetailsForSubmission(result);
      }
      if (!result?.memberId) {
        console.log(`Member id is missing from submission ${submissionId}`);
        return;
      }
    };
    loadSubmission();
    // Flag isMounted as false when disposing so that we don't update the submissions on disposal
    return () => {
      isMounted = false
    };
  }, [props.submissionId]);

  const onChange = (e) => {
    setHasChanges(true);
    onMemberDetailsChange(e);
  };

  const onRevert = (e) => {
    if (cachedMemberDetails) {
      setHasChanges(false);
      setMemberDetails(cachedMemberDetails);
    }
  };

  const onReject = async (e) => {
    setIsLoading(true);
    if (submission?.id) {
      let result = await submissionsApi.reject(submission.id);
      if (result.success && props.handled) {
        props.handled(submission.id);
      }
    }
    setIsLoading(false);
  };

  const onApprove = async (e) => {
    setIsLoading(true);
    if (submission?.id) {
      let result = await submissionsApi.approve(submission.id, memberDetails);
      if (result.success && props.handled) {
        props.handled(submission.id);
      }
    }
    setIsLoading(false);
  };

  return (
    <>
      <PageLoading show={isLoading} />
      <Box display="flex" flexDirection="column">

        
        <Box display="flex" flexDirection="row" justifyContent="flex-end">        

          {!submission.approvedDateUtc &&
           <Button margin="dense" variant="outlined" style={{"marginRight": 10}} onClick={onRevert} disabled={isLoading || !submission || !hasChanges}>REVERT</Button>}

          {!submission.approvedDateUtc &&
           <Button margin="dense" variant="outlined" style={{"marginRight": 10}} onClick={onReject}  disabled={isLoading || !submission}>REJECT</Button>}

          {!submission.approvedDateUtc &&
           <Button margin="dense" variant="outlined" color="primary" onClick={onApprove} disabled={isLoading || !submission}>APPROVE</Button>}
        </Box>
        <div style={{backgroundColor: "white", borderRadius: "4px", padding: "25px", boxShadow: "1px 0px 8px 3px rgba(0,0,0,0.07)"}}>
          <Typography variant="h6" style={{ color: "#11268E", fontWeight: "bold" }}>
              <MemberName id={submission?.memberId} />
          </Typography>

          <MemberDetailsForm state={memberDetails} id={props.submissionId} onChange={onChange} disabled={isDisabled} />
        </div>

      </Box>
    </>
  );
};

export default Approvals;