import React, { useEffect, useState } from 'react';
import { Container, Button } from '@material-ui/core';
import MemberDetailsForm, { useMemberDetailsForm } from './MemberDetailsForm';
import { submissionsApi } from '../../api';

const MemberDetails = (props) => {
  const member = props.member;
  const [isLoading, setIsLoading] = useState(false);
  const [hasChanges, setHasChanges] = useState(false);
  const [isSubmitted, setIsSubmitted] = useState(false);
  const [memberDetails, setMemberDetails, onMemberDetailsChange] =
    useMemberDetailsForm();

  const createFakeSubmission = (member) => {
    return { name: member?.name };
  };

  // Populate member details using latest submission
  useEffect(() => {
    if (Object.keys(member).length === 0) {
      // Dont do anything if member hasn't loaded
      return;
    }
    let updateForm = async () => {
      setIsLoading(true);
      const submission = await submissionsApi.member(member.id);
      if (submission.success) {
        const memberDetails = submission.data
          ? // Get the last updated submission
            JSON.parse(submission.data.data)?.memberDetails
          : // No submission, fake it with the existing members name
            createFakeSubmission(member);

        if (memberDetails) {
          setMemberDetails(memberDetails);
        }

        setIsSubmitted(submission.data?.submittedDateUtc !== null);
      }
      setIsLoading(false);
    };
    updateForm();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [member]);

  // Wrap form onChange method so we can set the has changes flag
  const onChange = (e) => {
    onMemberDetailsChange(e);
    setHasChanges(true);
    setIsSubmitted(false);
  };

  // Save submissions
  const onSaveDraft = async (e) => {
    setIsLoading(true);
    var result = await submissionsApi.save(member.id, memberDetails);
    if (result.success) {
      // There are no more local changes after saving a draft
      setHasChanges(false);
      // Saved a draft, its not submitted
      setIsSubmitted(false);
    }
    setIsLoading(false);
  };

  // Submit submissions for approval
  const onSubmitForPublishing = async (e) => {
    setIsLoading(true);
    var result = await submissionsApi.submit(member.id, memberDetails);
    if (result.success) {
      // There are no more local changes after submitting for approval
      setHasChanges(false);
      setIsSubmitted(true);
    }
    setIsLoading(false);
  };

  return (
    <>
      <Button
        variant='outlined'
        onClick={onSaveDraft}
        disabled={isLoading || !hasChanges}>
        SAVE DRAFT
      </Button>
      <Button
        variant='outlined'
        onClick={onSubmitForPublishing}
        color='primary'
        disabled={isLoading || isSubmitted}>
        SUBMIT FOR PUBLISHING
      </Button>
      <Container fixed>
        <MemberDetailsForm
          state={memberDetails}
          id={member.id}
          onChange={onChange}
          disabled={isLoading}
        />
      </Container>
    </>
  );
};

export default MemberDetails;
